<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Users </span>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="users"
      :server-items-length="pagination.total"
      :page-count="pagination.last_page"
      :options.sync="options"
      :page.sync="page"
      item-key="id"
      class="table-rounded row-pointer"
      :items-per-page="pagination.per_page"
      :loading="loading"
      :search="search"
      disable-sort
      :footer-props="{
        'items-per-page-options': [20,50,100],
      }"
      @click:row="onClickRow"
    >
      <!-- name -->
      <template #[`item.name`]="{item}">
        <div class="d-flex flex-column">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
        </div>
      </template>
      <template #[`item.isActive`]="{ item }">
        <v-chip :color="item.isActive ? 'success' : 'error'" dark>
          {{ item.isActive ? 'Active' : 'Inactive' }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'

export default {
  setup() {
    return {
      headers: [
        { text: 'NAME', value: 'name' },
        { text: 'PHONE', value: 'phone' },
        { text: 'EMAIL', value: 'email' },
        { text: 'STATUS', value: 'isActive' },
        { text: 'SIGNUP DATE', value: 'createdAt' },
      ],
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },
  data() {
    return {
      users: [],
      loading: true,
      search: '',
      page: 1,
      options: {},
      pagination: {},
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchApiData()
      },
    },
    deep: true,
  },
  created() {
    this.fetchApiData()
  },
  methods: {
    fetchApiData() {
      this.loading = true
      const { page, itemsPerPage } = this.options
      this.$http
        .get(`/users?page=${page}&per_page=${itemsPerPage}`)
        .then(res => {
          this.users = res.data.data
          this.pagination = res.data.meta
        })
        .finally(() => {
          this.loading = false
        })
    },
    onClickRow(item) {
      this.$router.push({
        name: 'programme-details',
        params: { id: item.id },
      })
    },
  },
}
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
